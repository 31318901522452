<template>
  <div>
  
    <v-row>
      <v-col cols="12" md="3"
        >   <v-chip color="green" v-if="termin.wter_zugabe == 1">
      Zusatztermin
    </v-chip>
        <b>{{ termin.datum }}</b></v-col
      >
      <v-col cols="12" md="3"
        >max. Teilnehmer-Zahl: <b>{{ termin.wter_count_max }}</b></v-col
      >
      <v-col cols="12" md="3">
        <b
          v-if="termin.wter_count_in < termin.wter_count_max"
          class="text-green"
          >bereits angemeldet: {{ countTeilnehmer }}</b
        >
     
      </v-col>
      <v-col cols="12" md="3"
        ><b
          ><a
            @click="showDialog = !showDialog"
            v-if="countTeilnehmer < termin.wter_count_max && !userTakesPart"
            >jetzt teilnehmen</a
          ></b
        >
        <b v-if="countTeilnehmer >= termin.wter_count_max" class="text-red"
          >ausgebucht</b
        >
      </v-col>
    </v-row>
    <v-row v-if="userTakesPart">
      <v-col cols="12" md="12"
        ><h3 class="text-green">
          Sie sind für dieses Webinar angemeldet
        </h3></v-col
      >
    </v-row>
    <v-row v-if="teilnehmer">
      <v-col cols="12" md="12"
        ><b>Teilnehmer: </b>
        <span v-for="t in teilnehmer" :key="t.wteil_id">
          <span v-if="t.wteil_pers_type <= 1">Zahnarzt </span
          ><span v-if="t.wteil_pers_type == 2">ZFA </span>{{ t.user_ort }}
          |
        </span>
      </v-col>
    </v-row>

    <div class="my-8 pa-2" style="background-color: #f0f0f0;"  v-if="countTeilnehmer >= termin.wter_count_max &&  termin.wter_webinar_id == 222">
    <v-row>
      <v-col cols="12" md="12"><h3><span class="text-green">Tipp:</span>&nbsp; Jetzt für Zusatztermin anmelden</h3>
      Wir versuchen so schnell wie möglich einen Zusatztermin zu organisieren. <a>Hier können Sie sich vormerken.</a>
      </v-col>
    </v-row>
    </div>
    <v-divider thickness="2" class="my-4"></v-divider>
    <v-dialog v-model="showDialogReserve" width="888">
      <v-card class="pa-4">
        <v-row
          ><v-col cols="11"></v-col
          ><v-col cols="1"
            ><v-btn icon @click="showDialogReserve = false">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-col
          ></v-row
        >
        <div v-if="!userStore.isLogged">
        <h2 >
          Bitte loggen Sie sich erst ein um sich für den Zusatztermin vormerken zu lassen.
        </h2>
        Wenn Sie noch keinen Account haben, können Sie sich <router-link class="noUnderline" to="/">hier kostenlos registrieren.</router-link>
      </div>
        <h2 v-if="userStore.isLogged">
          Melden Sie sich jetzt für den
          <span class="text-green">{{ termin.datum }}</span> kostenlos an
        </h2>
        <div v-if="userStore.isLogged">
          <v-form ref="formres" v-model="valid" lazy-validation>
            <v-text-field
              v-model="formDataReserve.wres_email"
              label="An welche E-Mail sollen wir Ihnen den Zoom-Link senden"
              :rules="[rules.required, rules.email]"
              required
            ></v-text-field>

            <v-text-field
              v-model="formDataReserve.wres_name"
              label="Wer nimmt an diesem Webinar teil"
              :rules="[rules.required]"
              required
            ></v-text-field>


            <v-btn color="primary" @click="submitFormReserve"
              >jetzt kostenlos vormerken</v-btn
            >
          </v-form>
        </div>
      </v-card></v-dialog
    >
    <v-dialog v-model="showDialog" width="888">
      <v-card class="pa-4">
        <v-row
          ><v-col cols="11"></v-col
          ><v-col cols="1"
            ><v-btn icon @click="showDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-col
          ></v-row
        >
        <div v-if="!userStore.isLogged">
        <h2 >
          Bitte loggen Sie sich erst ein, um kostenlos an diesem Webinar teilzunehmen
        </h2>
        Wenn Sie noch keinen Account haben, können Sie sich <router-link class="noUnderline" to="/">hier kostenlos registrieren.</router-link>
      </div>
        <h2 v-if="userStore.isLogged">
          Melden Sie sich jetzt für den
          <span class="text-green">{{ termin.datum }}</span> kostenlos an
        </h2>
        <div v-if="userStore.isLogged">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="formData.wteil_pers_email"
              label="An welche E-Mail sollen wir Ihnen den Zoom-Link senden"
              :rules="[rules.required, rules.email]"
              required
            ></v-text-field>

            <v-text-field
              v-model="formData.wteil_pers_name"
              label="Wer nimmt an diesem Webinar teil"
              :rules="[rules.required]"
              required
            ></v-text-field>

            <v-radio-group class="pl-4 ma-0" v-model="formData.wteil_pers_type">
              <v-radio
                key="0"
                label="Sie sind Zahnarzt/Zahnärtin"
                color="green"
                :value="1"
              ></v-radio>
              <v-radio
                key="1"
                label="Praxis-Mitarbeiter/in"
                color="green"
                :value="2"
              ></v-radio>
            </v-radio-group>

            <v-btn color="primary" @click="submitForm"
              >jetzt kostenlos anmelden</v-btn
            >
          </v-form>
        </div>
      </v-card></v-dialog
    >
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, defineProps, watchEffect } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useErrorStore } from "@/stores/errorStore";
  import { useSuccessStore } from "@/stores/successStore";
  import { useUserStore } from "@/stores/user";

  const errorStore = useErrorStore();
  const successStore = useSuccessStore();
  const userStore = useUserStore();
  const form = ref(null);
  const formres = ref(null);
  const valid = ref(false);
  const userTakesPart = ref(false);

  const props = defineProps({
    termin: {
      type: Object,
      default: () => ({}),
    },
  });

  const rules = {
    required: (value) => !!value || "Required.",
    email: (value) => /.+@.+/.test(value) || "E-mail must be valid.",
  };

  const submitForm = () => {
    if (form.value.validate()) {
      console.log("Form data:", formData);
      insertTeilnahme();
    }
  };

  const submitFormReserve = () => {
    if (formres.value.validate()) {
      console.log("Form data:", formDataReserve);
      insertReserve();
    }
  };


  const formDataReserve = reactive({
    action: "insertReserve",
    wres_user_id: 0,
    wres_webinar_id: props.termin.wter_webinar_id,
    wres_email: "",
    wres_name: "",
    wteil_status: 1,
  });

  const formData = reactive({
    action: "insertTeilnahme",
    mailtermin: props.termin.datum,
    wteil_user_id: 0,
    wteil_termin_id: props.termin.wter_id,
    wteil_webinar_id: props.termin.wter_webinar_id,
    wteil_pers_email: "",
    wteil_pers_name: "",
    wteil_pers_type: 1,
  });
  const showDialog = ref(false);
  const showDialogReserve = ref(false);
  const teilnehmer = ref(false);
  const countTeilnehmer = ref(0);

  const getTeilnehmer = async () => {
    console.log("get Teilnehmer");
    try {
      const data = {
        wteil_termin_id: props.termin.wter_id,
        action: "getTeilnehmerPerWebId",
      };
      const resp = await BaseCalls.postWebinar(data);
      if (resp.data && resp.data.teilnehmer) {
        teilnehmer.value = resp.data.teilnehmer;
        countTeilnehmer.value = resp.data.teilnehmer.length;
        checkUserParticipation(userStore.userData.user_id);

        // setLoading(false); // Diese Zeile kommentiert, da setLoading nicht definiert ist
      }
    } catch (err) {
      console.log("Fehler bei der Aktion:", err.message);
    }
  };

  const checkUserParticipation = (user_id) => {
    // Suchen nach dem Teilnehmer mit user_id 448 im Array
    const userExists = teilnehmer.value.some(
      (participant) => participant.user_id === user_id
    );

    // Setze userTakesPart auf true, falls der Teilnehmer gefunden wurde
    userTakesPart.value = userExists;
  };

  async function insertReserve() {
    try {
      const resp = await BaseCalls.postWebinarUser(formDataReserve); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.success) {
        successStore.setSuccess(
          true,
          "Wir haben Ihre Vormerkung erhalten",
          "Wir schicken Ihnen so schnell wie möglich den Zoom-Link."
        );
        getTeilnehmer();
        showDialogReserve.value = false;
      } else {
        errorStore.setError(true, resp.data.errortext);
      }
    } catch (err) {
      errorStore.setError(
        true,
        "Ihre Anmeldung konnten nicht gespeichert werden"
      );
    }
  }
  async function insertTeilnahme() {
    try {
      const resp = await BaseCalls.postWebinarUser(formData); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.success) {
        successStore.setSuccess(
          true,
          "Wir haben Ihre Anmeldung erhalten",
          "Wir schicken Ihnen so schnell wie möglich den Zoom-Link."
        );
        getTeilnehmer();
        showDialog.value = false;
      } else {
        errorStore.setError(true, resp.data.errortext);
      }
    } catch (err) {
      errorStore.setError(
        true,
        "Ihre Anmeldung konnten nicht gespeichert werden"
      );
    }
  }
  watchEffect(() => {
    if (userStore.userData && userStore.userData.user_id) {
      formData.wteil_user_id = userStore.userData.user_id;
      formData.wteil_pers_email = userStore.userData.user_email;
      formData.wteil_pers_name = `${userStore.userData.user_vorname} ${userStore.userData.user_name}`;
   
      formDataReserve.wres_user_id = userStore.userData.user_id;
      formDataReserve.wres_email = userStore.userData.user_email;
      formDataReserve.wres_name = `${userStore.userData.user_vorname} ${userStore.userData.user_name}`;
    }
  });
  onMounted(async () => {
    await getTeilnehmer();
  });
  console.log(props.termin);
</script>
