// Styles
import "@mdi/font/css/materialdesignicons.css"; // Importieren der Material Design Icons
import "vuetify/styles"; // Importieren der Vuetify-Styles

// Vuetify
import { createVuetify } from "vuetify";

// Erstellen und Exportieren der Vuetify-Instanz mit der Konfiguration
export default createVuetify({
  theme: {
    defaultTheme: "light", // Standard-Theme setzen
    themes: {
      light: {
        colors: {
          primary: "#2196f3", // Primärfarbe hier einstellen
          error: '#e54c39',
          // Fügen Sie hier weitere Farbanpassungen hinzu
        },
      },
      dark: {
        colors: {
          primary: "#BBDEFB", // Primärfarbe für den Dunkelmodus
          // Fügen Sie hier weitere Farbanpassungen hinzu
        },
      },
    },
  },
  // Weitere Vuetify-Optionen hier einfügen
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
