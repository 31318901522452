<template>
  <v-container>
    <div class="my-4 pa-4">
 <v-alert v-if="showError && !showAlreadyUser" type="error"><h2 class="text-white">{{showErrorText}}</h2></v-alert>
 <v-alert v-if="showAlreadyUser" type="success"><h2 class="text-white">Sie sind bereits registriert. Bitte loggen Sie sich ein.</h2></v-alert>
 <v-row>
  <v-col cols="12" md="8"><h2>Jetzt kostenlos anmelden |  Recruiting mit Social Media <sup class="text-green">neu</sup></h2>
Sie können sich jetzt direkt mit Facebook anmelden und kostenlos an den WaizmannWebinaren teilnehmen. Oder Sie melden sich mit Ihrer E-Mail Adresse an.
Beide Varianten sind kostenlos.<br />
<b>Einfach anmelden und Termin auswählen!</b> Wir freuen uns auf Sie!

<v-row class="my-4">
        <v-col cols="12" md="6">
        <HFaceBookLogin 
  v-slot="fbLogin" 
  app-id="563907036335909" 
  @onSuccess="onSuccess" 
  @onFailure="onFailure" 
  scope="email,public_profile"
  fields="id,name,email,first_name,last_name,birthday"
>
<v-btn @click="fbLoginStarted = true; fbLogin.initFBLogin()" color="primary">
  <v-icon class="mr-2" left>mdi-facebook</v-icon>
  Jetzt mit Facebook anmelden
</v-btn>

</HFaceBookLogin>
        </v-col>


      <v-col cols="12" md="6">
        <v-btn @click="showRegisterUser =! showRegisterUser">
          <v-icon class="mr-2" left>mdi-at</v-icon> 
       Jetzt mir E-Mail E-Mail anmelden
        </v-btn>

      </v-col>
      </v-row>
      <v-row>
      <v-col cols="12" md="12">
        <RegisterUser v-if="showRegisterUser" />
      </v-col>
    </v-row>
    <v-row style="border-top: 4px solid #E5EDF4;" class="mt-2">
              <v-col cols="12" class="pl-4">
                <ul class="ml-4">
  <li><a href="/info/impressum">hier finden Sie unsere Datenschutzerklärung</a></li>
  <li><a href="/info/nutzungsbedingungen">hier finden Sie unsere Nutzungsbedingungen</a></li>
  <li><a href="/info/datenloeschen">Anleitung zur Löschung Ihrer Benutzerdaten</a></li>
  <!-- <li><a @click="checkLoginStatus">test</a></li> -->
</ul>
              </v-col>
            </v-row>
</v-col>
<v-col cols="12" md="4">
  <v-card elevation="0" class="px-4" style="cursor: pointer;">
            <v-row>
              <v-col cols="12" style="height: 420px;">
                <v-row style="border-bottom: 4px solid #E5EDF4;">
                  <v-col cols="9">
                    <h3>WaizmannWebinar</h3>
                    <h2>Recruiting mit Social Media <sup class="text-green">2025</sup></h2>
                  </v-col>
                  <v-col cols="3">
                    <v-icon style="font-size: 36px;">mdi-school</v-icon>
                  </v-col>
                </v-row>
                <v-row class="mt-4" style="border-bottom: 4px solid #E5EDF4;">
                  <v-col cols="6">
                    <img style="width: 160px" :src="require('@/assets/site/zacoach.jpg')" />
                    <ul v-if="termine.length > 0">
                      <li v-for="t in termine.slice(0, 3)" :key="t.wter_id">
                        <span v-if="t.wter_status == 0">{{ t.datum }}</span>
                        <span class="text-red" v-else-if="t.wter_status == 1">
                          {{ t.datum }} <br />bereits beendet
                        </span>
                      </li>
                    
                    </ul>
                  </v-col>
                  <v-col cols="6" class="pl-4">
                    <b>Dipl.-Psych. Frank Frenzel</b>
                    <ul>
                      <li>Führender Zahnarzt-Coach in Deutschland</li>
                      <li>
                        Betreiber des Nr. 1 Youtube-Kanals für Praxis-Management und
                        -Kommunikation: @MeinZahnarztCoach
                      </li>
                     
                    </ul>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
         
          </v-card>
        

</v-col>
 </v-row>
 
    </div>
  </v-container>
</template>

<script setup>
import { ref, watch, reactive,onMounted } from 'vue';
import { HFaceBookLogin } from '@healerlab/vue3-facebook-login';
import { useUserStore } from '@/stores/user';
import BaseCalls from "@/services/BaseCalls";
import { useErrorStore } from "@/stores/errorStore";
import { useSuccessStore } from "@/stores/successStore";
import RegisterUser from "./../components/user/RegisterUser.vue";
import { useRouter } from "vue-router";
const userStore = useUserStore();
const errorStore = useErrorStore();
const successStore = useSuccessStore();
const router = useRouter();
const fbResponse = ref(null);
const showRegisterUser = ref(false);
// fbResponse.value = {
//   "authResponse": {
//     "userID": "9400319236654666",
//     "expiresIn": 6266,
//     "accessToken": "0Fuxn8nIT_urbMq51_S5ZroYOFhB3PzjsUGtZ4aXm8Y",
//     "signedRequest": "EAAIA3taWTyUBO1nSiOAja63j5MH169yXNM9qpZARjBZB8uG8l9BdxVL20ABuQoKrxasuA5VhHmlZAvLW0mcZBm0ht3WzzvvSiZC2NflaxnvmG7IqJaoM4G6hEguUerJ0jZB3MqAriWmwBGNgS0pmLYEPVm0pfdvxPvJ8BG5UwL0Cx1O0SW7WzPSJFFZCZBSAoa5lHrzN3s20PwFre1vWSAZDZD",
//     "graphDomain": "facebook",
//     "data_access_expiration_time": 1740755734
//   },
//   "status": "connected",
//   "authInfo": {
//     // "id": "9400319236654666",
//     // "name": "Florian Meier",
//     // "email": "mails738@gmail.com",
//     // "first_name": "Florian",
//     // "last_name": "Meier"
//       "id": "8724783380936011",
//       "email": "tobizorn@yahoo.com",
//       "user_action": "startFacebookLogin",
//       "last_name": "Zorn",
//       "first_name": "Tobi"
//   }
// };
const fbError = ref(null);
const showError = ref(false);
const showErrorText = ref("Fehler beim Starten des Facebook Login");
const showAlreadyUser = ref(false);
const fbLoginStarted = ref(false);
const userLogin = reactive({
    id: 0,
    email: "",
    user_action: "startFacebookLogin",
    last_name: "",
    first_name: "",
 
  });


  const termine = ref([]);
  const getTermine = async () => {
    try {
      const data = { wter_webinar_id: 3, action: "getWebinarTerminePerId" };
      const resp = await BaseCalls.postWebinar(data);
      if (resp.data && resp.data.termine) {
        termine.value = resp.data.termine;
        // setLoading(false); // Diese Zeile kommentiert, da setLoading nicht definiert ist
      }
    } catch (err) {
      console.log("Fehler bei der Aktion:", err.message);
    }
  };
const checkLoginStatus = async () => {
  console.log('checkLoginStatus');

  userLogin.id = fbResponse.value?.authResponse?.userID;
  userLogin.email = fbResponse.value?.authInfo?.email;
  userLogin.first_name = fbResponse.value?.authInfo?.first_name;
  userLogin.last_name = fbResponse.value?.authInfo?.last_name;
  console.log(fbResponse.value);

  try {
    const resp = await BaseCalls.postUser(userLogin);

    if (resp.data && resp.data.error) {
      showError.value = true;
      showErrorText.value = resp.data.errortext;
      if (!resp.data.alreadyUser) {
        errorStore.setError(true, resp.data.errortext);
      }
      if (resp.data.alreadyUser == 'Email') {
        showAlreadyUser.value = true;
      }
    }

    if (resp.data && resp.data.user) {
      userStore.setUser(resp.data.user);
      userStore.isLogged = true;
      userStore.showLogin = false;
      if (resp.data.alreadyUser == 'Facebook') {
        successStore.setSuccess(
          true,
          "Sie können jetzt kostenlos die gewünschten Services auswählen.",
          ""
        );
      }
      if (resp.data.alreadyUser == 'Email') {
        successStore.setSuccess(
          true,
          "Sie sind jetzt eingeloggt.",
          ""
        );
      }
      router.push("/einladung/3");
     // router.push("/webinare/webinar_recruiting_zahnarzt_zfa");
      // if (resp.data.user.user_id == 81818) {
      //   router.push("/waizmanntabellen_bestellen");
      // }
    }
  } catch (err) {
    errorStore.setError(true, "Beim Anmelden ist ein Fehler aufgetreten.");
  }
};
function onSuccess(response) {
  console.log('Eingeloggt:', response);
  fbResponse.value = response;
}
function onFailure(error) {
  console.error('Login fehlgeschlagen:', error);
  fbError.value = error;
}

// Watcher für userID
onMounted(() => {
  getTermine();
});
watch(
  () => fbResponse.value?.authResponse?.userID,
  (newUserID) => {
    if (newUserID && newUserID > 0 && !userStore.isLogged && fbLoginStarted.value) {
      userLogin.id = fbResponse.value?.authResponse?.userID;
      userLogin.email = fbResponse.value?.authInfo?.email;
      userLogin.first_name = fbResponse.value?.authInfo?.first_name;
      userLogin.last_name = fbResponse.value?.authInfo?.last_name;
     checkLoginStatus();
    }
  },
  { immediate: true }
);

</script>
