<template>
  <v-app>
    <ErrorOverlay />
    <LoaderOverlay />
    <SuccessOverlay />
    <v-dialog width="666" v-model="showLogin">
      <DialogLogin showClose="true" />
    </v-dialog>

    <div
      v-if="scrollVisible && sharedDataStore.isDesktop()"
      style="
        background-color: #E5EDF4;
        height: 40px;
        position: fixed;
        width: 100%;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
      class="pa-2"
    >
      <!-- Left-aligned content -->
  
      <span
        style="font-weight: normal; font-size: 16px; height: 30px;"
        class="mt-0 pa-0 greytext"
      >
        &nbsp;&nbsp;<b>Das Original</b> | seit 1995
        <span class="mx-8">
          über <b>10.000 Zahnärzte</b> haben bereits einen TeamWaizmann-Account
        </span>
      </span>

      <!-- Right-aligned content -->
      <span
        v-if="sharedDataStore.isDesktop()"
        style="font-weight: normal; font-size: 16px; height: 30px;"
        class="mt-0 pa-0 greytext"
      >
        &copy; TeamWaizmann | <router-link to="/info/impressum">Impressum</router-link>
      </span>
    </div>

    <v-app-bar
      v-scroll="makeSmall"
      :style="styleSwitch"
      app
      elevation="1"
      clipped-left
      :extended="showExtension"
      :extension-height="showExtensionHeight"
    >
      <!-- Hauptinhalt der App-Bar -->
      <v-app-bar-nav-icon
        style="font-size: 18px"
        @click="showMenue = !showMenue"
      ></v-app-bar-nav-icon>

      <router-link to="/" style="text-decoration: none" class="text-black">
        <span v-if="sharedDataStore.isDesktop()" style="font-size: 36px; font-weight: bold; cursor: pointer">
          Team<span class="text-blue">Waizmann</span>
        </span>
        <span v-if="!sharedDataStore.isDesktop()" style="font-size: 18px; font-weight: bold; cursor: pointer">Team<span class="text-blue">Waizmann</span></span>
      </router-link>

      <span v-if="sharedDataStore.isDesktop()">
        <span v-if="!userStore.isLogged">
        <v-btn text class="ml-4" style="font-size:20px; letter-spacing: 0px; " @click="toggleExtension(1)">
          WaizmannTabellen anfordern
        </v-btn>
        <v-btn text class="ml-4"  style="font-size:20px;letter-spacing: 0px;" @click="toggleExtension(2)">
          WaizmannWebinare
        </v-btn>
        <v-btn text class="ml-4" style="font-size:20px;letter-spacing: 0px;"  @click="toggleExtension(3)">
          PraxisWissen
        </v-btn>
        <v-btn text class="ml-4" style="font-size:20px;letter-spacing: 0px;"  @click="toggleExtension(4)">
          PraxisLinks
        </v-btn>
      </span>
      <span v-if="userStore.isLogged">
        <v-btn text class="ml-4" style="font-size:20px; letter-spacing: 0px; " @click="goToLink(1)">
          WaizmannTabellen anfordern
        </v-btn>
        <v-btn text class="ml-4"  style="font-size:20px;letter-spacing: 0px;" @click="goToLink(2)">
          WaizmannWebinare
        </v-btn>
        <v-btn text class="ml-4" style="font-size:20px;letter-spacing: 0px;"  @click="goToLink(3)">
          PraxisWissen
        </v-btn>
        <v-btn text class="ml-4" style="font-size:20px;letter-spacing: 0px;"  @click="goToLink(4)">
          PraxisLinks
        </v-btn>
      </span>
      </span>

      <v-spacer></v-spacer>

      <v-icon v-if="sharedDataStore.isDesktop()" >mdi-account</v-icon>
      <v-btn
        size="large"
        color="primary"
        v-if="!userStore.isLogged"
        @click="toggleLogin"
      >Zahnarzt-Login</v-btn>
      <v-btn
        outlined
        size="large"
        color="primary"
        @click="startLogout()"
        v-if="userStore.isLogged"
      >Logout</v-btn>

      <!-- Extension Slot for the second line -->
      <template #extension v-if="showExtension">
        <div v-if="currentToolbar === 1" style="width: 100%;"  >
      <TopMenueBestellen />
        </div>
        <div v-if="currentToolbar === 2" style="width: 100%;"  >
      <TopMenueWebinare />
        </div>
        <div v-if="currentToolbar === 3" style="width: 100%;"  >
      <TopMenuePraxiswissen />
        </div>

        <div v-if="currentToolbar === 4" style="width: 100%;"  >
      <TopMenuePraxislinks />
        </div>
        
        
      </template>
    </v-app-bar>

    <v-navigation-drawer  @click="showExtension = false"   v-model="showMenue" style="margin-top: 50px" app clipped>
              <MenueUser v-if="userStore.isLogged && showMenue" />
              <MenueDefault v-if="!userStore.isLogged && showMenue" />
            </v-navigation-drawer>

    <v-main style="margin-top: 20px;margin-left: -20px" @click="showExtension = false">
      <v-container fill-height>
        <v-row justify="center">
          <v-col cols="12" md="12">
          
            <router-view
              style="min-height: 666px; margin-top: 0px"
            ></router-view>
    
       
          </v-col>
        </v-row>
      
      </v-container>
    </v-main>

   
  </v-app>
</template>

<script setup>
  import { ref, computed, onMounted, watch } from "vue";
  import { useUserStore } from "@/stores/user";
  import DialogLogin from "./components/user/DialogLogin.vue";
  import MenueUser from "./components/user/MenueUser.vue";
  import TopMenueBestellen from "./components/site/TopMenueBestellen.vue";
  import TopMenueWebinare from "./components/site/TopMenueWebinare.vue";
  import TopMenuePraxiswissen from "./components/site/TopMenuePraxiswissen.vue";
  import TopMenuePraxislinks from "./components/site/TopMenuePraxislinks.vue";
  import MenueDefault from "./components/site/MenueDefault.vue";
  import ErrorOverlay from "@/components/base/ErrorOverlay.vue";
  import LoaderOverlay from "@/components/base/LoaderOverlay.vue";
  import SuccessOverlay from "@/components/base/SuccessOverlay.vue";
  // import { useLoaderStore } from "@/stores/loaderStore";
  import { useErrorStore } from "@/stores/errorStore";
  import { useSuccessStore } from "@/stores/successStore";
 
  import { useRouter, useRoute } from "vue-router";
  import BaseCalls from "@/services/BaseCalls";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();

  const router = useRouter();
  const route = useRoute();
  // const loaderStore = useLoaderStore();
  const errorStore = useErrorStore();
  const successStore = useSuccessStore();

  watch(
    () => route.path,
    () => {
      window.scrollTo(0, 0);
    }
  );

  function goToLink(id){
    if (id == 1){
      router.push({
        name: "waizmanntabellen_bestellen",
      });
    }
    if (id == 2){
      router.push({
        name: "webinareindex",
      });
    }
  }
  function toggleLogin() {
  
    showLogin.value = !showLogin.value;
    userStore.showLogin = true;
  }

  const showLogin = computed({
    get() {
      return userStore.showLogin;
    },
    set(value) {
      userStore.showLogin = value;
    }
  });

  const showMenue = ref(true);
  const scrollVisible = ref(true);
  let styleSwitch = ref("margin-top:40px;"); // `let` wenn du nicht `ref` für Styles verwenden willst, oder `ref` für Reaktivität
  const userStore = useUserStore();

  // const user2 = reactive({
  //   name: "Alice",
  //   age: 30,
  // });
  const user = computed(() => userStore.userData);
  console.log(user);
  // function setUser(user) {
  //   userStore.setUser(user);
  // }

  // setUser(user2);

  async function startLogout() {
    let data = {};
    data.user_action = "startLogout";

    try {
      const resp = await BaseCalls.postUser(data);
      if (resp.data && resp.data.success) {
        userStore.setUser(false);
        userStore.isLogged = false;
        successStore.setSuccess(
          true,
          "Logout erfolgreich",
          "Sie sind jetzt ausgeloggt"
        );
        router.push("/");
      } else {
        // Hier haben wir keinen `err`, also verwenden wir eine allgemeine Fehlermeldung
        errorStore.setError("Beim Abmelden ist ein Fehler aufgetreten.");
      }
    } catch (err) {
      // Im Falle eines Fehlers in der Anfrage selbst (z.B. Netzwerkfehler)
      errorStore.setError(
        err.message || "Beim Abmelden ist ein technischer Fehler aufgetreten."
      );
    }
  }
  function makeSmall() {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    // Zugriff auf `scrollVisible` und `styleSwitch` ohne `this`
    if (currentScrollPosition > 100) {
      // Annahme, dass `scrollPosition` durch einen bestimmten Wert ersetzt wurde
      styleSwitch.value = "margin-top:0px;";
      scrollVisible.value = false;
      showExtension.value = false;
    } else {
      styleSwitch.value = "margin-top:40px;";
      scrollVisible.value = true;
    }
    if (!sharedDataStore.isDesktop()){
      styleSwitch.value = "margin-top:0px;";
      scrollVisible.value = false;
      showExtension.value = false;
    }
   
  }

  onMounted(() => {
    (async () => {
      if (!userStore.isLogged) {
        console.log("Benutzer ist noch nicht eingeloggt");
        try {
          const data = {};
          data.action = "loggedUser";
          const resp = await BaseCalls.getUser(data);
          if (resp.data && resp.data.success) {
            console.log("Erfolgreiche Aktion");
            userStore.setUser(resp.data.user);
            userStore.isLogged = true;
          }
        } catch (err) {
          console.log("Fehler bei der Aktion:", err.message);
        }
      } else {
        console.log("Benutzer ist nicht eingeloggt");
      }

      // Initialisiere showLogin, falls es nicht gesetzt ist
      if (userStore.showLogin === undefined) {
        userStore.showLogin = false;
      }
      if (!sharedDataStore.isDesktop()){
      styleSwitch.value = "margin-top:0px;";
      scrollVisible.value = false;
      showExtension.value = false;
    }
    })();
  });

  // Reactive variables to control the extension and current toolbar
  const showExtension = ref(false);
  const showExtensionHeight = ref(140);
  const currentToolbar = ref(null);

  // Function to toggle the extension and display the corresponding toolbar
  function toggleExtension(toolbarNumber) {
    if (currentToolbar.value === toolbarNumber && showExtension.value) {
      // Blendet die Erweiterung aus, wenn die gleiche Toolbar erneut geklickt wird
      showExtension.value = false;
      currentToolbar.value = null;
    } else {
      // Blendet die Erweiterung ein und setzt die aktuelle Toolbar
      showExtension.value = true;
      currentToolbar.value = toolbarNumber;
    }

    if (toolbarNumber === 1) {
      showExtensionHeight.value = 200;
    } else if (toolbarNumber === 2) {
      showExtensionHeight.value = 200;
    }
    else if (toolbarNumber === 3) {
      showExtensionHeight.value = 220;
    } else {
      showExtensionHeight.value = 220;
    }

    // Scrollt beim Auslösen der Funktion nach ganz oben
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
</script>
<style>

  a {
    color: #1976d2;
    cursor: pointer;
  }
  ul {
    margin-left: 12px;
  }
  li {
    margin-bottom: 2px;
  }
  
  .head20 {
    font-size:20px;
    font-weight: bold;
  }
  .head18 {
    font-size:18px;

  }

  .greyground {
    background-color: #f2f2f2;
  }
  .noLink {
    color: black;
    text-decoration: none !important;
  }
  .v-btn {
    text-transform: none !important;
  }
  body,
  .v-card-text {
    font-size: 16px; /* Setzen Sie die gewünschte Standardtextgröße */
  }

  /* Setzt die Textfarbe für v-app und alle Kindelemente */
  v-app, v-app * {
    color: #222;
  }

  .v-btn .v-btn__content {
    font-weight: 600; /* Gewünschte Schriftstärke */
  }

  .noUnderline {
    text-decoration: none !important;
  }

  v-main {
    flex: 1 0 auto;
  }
  v-footer {
    flex-shrink: 0;
  }
</style>
