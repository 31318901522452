<template>
  <v-container w v-if="userStore.isLogged">
<v-alert v-if="showSuccess" type="success"><h2 class="text-white">Wir haben Ihre Anmeldung erhalten! Sie erhalten einen Zoom-Link per E-Mail.</h2></v-alert>
    
    <v-row v-if="webinarId == 3 && !showSuccess">
      <v-col cols="12" md="9">
        <h2>
        <span class="text-green">Dental-Recruiting 2025</span> 
      </h2>
      <v-row>
        <v-col cols="12">
      7 günstige und bewährte Tipps,
        mit Social Media neue Mitarbeiterinnen zu gewinnen
        <b>kostenlos auf TeamWaizmann</b>
      </v-col>
      </v-row>
      <v-row class="my-2">
        <v-col cols="12">
          <span v-if="terminId == 0">
          <h3 >Schritt 1 - Termin wählen</h3>
        <v-radio-group v-model="terminId">
          <v-radio v-for="t in termine" :key = "t.wter_id" :value="t.wter_id" :label="sharedData.formatGermanDate(t.wter_beginn)" ></v-radio>
        </v-radio-group>
        </span>
        <span v-if="terminId >  0">
      <h3>Schritt 2 - wer soll teilnehmen?</h3>
      <v-form ref="form">
        <v-text-field
          v-model="formData.wteil_pers_email"
          label="An welche E-Mail sollen wir Ihnen den Zoom-Link senden"
          :rules="[rules.required, rules.email]"
        ></v-text-field>

        <v-text-field
          v-model="formData.wteil_pers_name"
          label="Wer nimmt an diesem Webinar teil"
          :rules="[rules.required]"
        ></v-text-field>

        <v-radio-group
          class="pl-4 ma-0"
          v-model="formData.wteil_pers_type"
          :rules="[rules.required]"
        >
          <v-radio
            key="0"
            label="Sie sind Zahnarzt/Zahnärztin"
            color="green"
            :value="1"
          ></v-radio>
          <v-radio
            key="1"
            label="Praxis-Mitarbeiter/in"
            color="green"
            :value="2"
          ></v-radio>
        </v-radio-group>

        <span v-if="showFormAdress">
        <v-row >
        <v-col cols="12"><h3>Bitte ergänzen Sie noch Ihre Adresse</h3></v-col>
      </v-row>
       
  
      <v-row>
        <v-col cols="12" md="9">
        
          <v-text-field
            v-model="updateUser.user_strasse"
            label="Strasse"
            type="char"
            required
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="updateUser.user_hsnr"
            label="Hausnummer"
            type="char"
            required
          ></v-text-field
        ></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="updateUser.user_plz"
            label="PLZ"
            type="char"
            required
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="9">
          <v-text-field
            v-model="updateUser.user_ort"
            label="Ort"
            type="char"
            required
          ></v-text-field
        ></v-col>
      </v-row>
    </span>
        <v-btn color="primary" @click="submitForm">
          jetzt kostenlos anmelden
        </v-btn>
      </v-form>

    <!-- {{ terminId }} <hr>
    {{ formData }}<hr>
    {{  updateUser }} -->
      </span>
      </v-col>
    </v-row>
     
</v-col>
  </v-row>
</v-container>
</template>

<script setup>
  import { ref, onMounted, watch,reactive } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useUserStore } from "@/stores/user"; 
  import { useSharedDataStore } from "@/stores/sharedData";
  import { useErrorStore } from "@/stores/errorStore";
  import { useSuccessStore } from "@/stores/successStore";

  const errorStore = useErrorStore();
  const successStore = useSuccessStore();

  const userStore = useUserStore();
  const sharedData = useSharedDataStore();
  const webinarId = ref(3);
  const termine = ref([]);

  const terminId = ref(0);
  const form = ref(null);

  const showSuccess = ref(false);
  const showFormAdress = ref(false);
  const formData = reactive({
    action: "insertTeilnahme",
    wteil_user_id: 0,
    wteil_termin_id: 0,
    wteil_webinar_id: webinarId.value,
    wteil_pers_email: "",
    wteil_pers_name: "",
    wteil_pers_type: 1,
  });

  const updateUser = reactive({});
  Object.assign(updateUser, userStore.userData);
  updateUser.user_action = "updateAccount";

  const rules = {
    required: (value) => !!value || "Pflichtfeld.",
    email: (value) => /.+@.+\..+/.test(value) || "E-Mail muss gültig sein.",
  };
  async function submitForm() {
    if (!form.value) {
      console.log('Form is not available');
      return;
    }

    const { valid, errors } = await form.value.validate();
    console.log('Is form valid:', valid);
    console.log('Validation errors:', errors);

    if (valid && userStore.userData.user_id > 0) {
      console.log('submitForm', formData);
      await insertTeilnahme();
    } else {
      console.log('Form is invalid or user_id is missing');
    }
  }


  async function insertTeilnahme() {
    formData.wteil_termin_id = terminId.value;
    formData.wteil_user_id = userStore.userData.user_id;
    try {
      const resp = await BaseCalls.postWebinarUser(formData); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.success) {
        successStore.setSuccess(
          true,
          "Wir haben Ihre Anmeldung erhalten",
          "Wir schicken Ihnen so schnell wie möglich den Zoom-Link."
        );
        showSuccess.value = true;
        if (!showFormAdress.value) {
          updateUserAddress();
        }
        
      } else {
        errorStore.setError(true, resp.data.errortext);
      }
    } catch (err) {
      errorStore.setError(
        true,
        "Ihre Anmeldung konnte nicht gespeichert werden"
      );
    }
  }

  const updateUserAddress = async () => {
console.log('updateUserAddress',updateUser);
//successStore.setSuccess(true, "Ihr Daten wurde geändert");
try {
await BaseCalls.postUserLogged(updateUser);

} catch (err) {
  errorStore.setError(
    err.message || "Beim Anmelden ist ein Fehler aufgetreten."
  );
}
};
  const getTermine = async () => {
    console.log("getTermine");
    try {
      const data = {
        wter_webinar_id: webinarId.value,
        action: "getWebinarTerminePerId",
      };
      const resp = await BaseCalls.postWebinar(data);
      if (resp.data && resp.data.termine) {
        termine.value = resp.data.termine;
      }
    } catch (err) {
      console.log("Fehler bei der Aktion:", err.message);
    }
  };

  onMounted(() => {
    console.log("onMounted", userStore.isLogged);
    if (userStore.isLogged && termine.value.length === 0) {
      getTermine();
    
      if (
        userStore.userData.user_strasse == "" ||
        userStore.userData.user_plz == "" ||
        userStore.userData.user_ort == ""
      ) {
        showFormAdress.value = true;
      }
    }
  });
  watch(
    () => userStore.userData,
    () => {

      Object.assign(updateUser, userStore.userData);
     // console.log('watch updateUser',updateUser);
      if (
        userStore.userData.user_strasse == "" ||
        userStore.userData.user_plz == "" ||
        userStore.userData.user_ort == ""
      ) {
        showFormAdress.value = true;
      }
    },
    {
      deep: true,
    }
  );
  watch(() => userStore.isLogged, (neuerWert) => {
    if (neuerWert && termine.value.length === 0) {
      getTermine();
      formData.wteil_user_id = userStore.userData.user_id;
      if (userStore.userData.user_id == 1) {
        insertTeilnahme();
      }
    }
  });
</script>
