<template>
  <v-container>
    <WebinarIndex />
  </v-container>
</template>

<script setup>
  import { ref, onMounted, getCurrentInstance } from "vue";
  import { useRoute } from "vue-router";
  import WebinarIndex from "./../components/veranstaltung/WebinarIndex.vue";

  const route = useRoute();
  const showView = ref("");
  showView.value = route.params.info;

  onMounted(() => {
    const { proxy } = getCurrentInstance();
    if (showView.value == "webinar_by_by_bema") {
      proxy.$updateDocumentHead(
        "Webinar TeamWaizmann -  By By Bema",
        "Webinar TeamWaizmann -  By By Bema"
      );
    }
    if (showView.value == "webinar_profit_booster_professionelle_zahnreinigung") {
      proxy.$updateDocumentHead(
        "Webinar TeamWaizmann -  Profit Booster Professionelle Zahnreinigung",
        "Webinar TeamWaizmann -  Profit Booster Professionelle Zahnreinigung"
      );
    }
    if (showView.value == "webinar_recruiting_zahnarzt_zfa") {
      proxy.$updateDocumentHead(
        "Webinar TeamWaizmann -  Social Media Recruiting Zahnarzt ZFA",
        "Webinar TeamWaizmann -  Social Media Recruiting Zahnarzt ZFA"
      );
    }
  });
</script>
