<template>
  <v-container>
    <ImpressumInfo :key="keyComponent" v-if="showView == 'impressum'" />
    <DatenLoeschen :key="keyComponent" v-if="showView == 'datenloeschen'" />
    <PraxiswissenInfo :key="keyComponent" v-if="showView == 'praxiswissen'" />
    <NutzungsBedingungen :key="keyComponent" v-if="showView == 'nutzungsbedingungen'" />
  </v-container>
</template>

<script setup>
  import { ref, onMounted, getCurrentInstance,watch } from "vue";
  import { useRoute } from "vue-router";
  import ImpressumInfo from "./../components/info/ImpressumInfo.vue";
  import PraxiswissenInfo from "./../components/info/PraxiswissenInfo.vue";
  import DatenLoeschen from "./../components/info/DatenLoeschen.vue";
  import NutzungsBedingungen from "./../components/info/NutzungsBedingungen.vue";

  const route = useRoute();
  const showView = ref(route.params.site);
  const keyComponent = ref(0);

  watch(
    () => route.params.site,
    (newSite) => {
      showView.value = newSite;
      keyComponent.value++;
    }
  );

  onMounted(() => {
    const { proxy } = getCurrentInstance();
    if (showView.value == "impressum") {
      proxy.$updateDocumentHead(
        "Impressum TeamWaizmann",
        "Impressum TeamWaizmann"
      );
    }
    if (showView.value == "datenloeschen") {
      proxy.$updateDocumentHead(
        "So löschen Sie Ihre Daten bei TeamWaizmann",
        "So löschen Sie Ihre Daten bei TeamWaizmann"
      );
    }
    if (showView.value == "nutzungsbedingungen") {
      proxy.$updateDocumentHead(
        "Nutzungsbedingungen TeamWaizmann",
        "Nutzungsbedingungen TeamWaizmann"
      );
    }
  });
</script>
