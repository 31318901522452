<template>
  <v-container>
      <h2 class="mb-2">
        <span class="text-green">Dental-Recruiting 2025</span>: 7 günstige und bewährte Tipps,
        mit Social Media neue Mitarbeiterinnen zu gewinnen |
        <span class="text-blue">kostenlos</span> auf TeamWaizmann
      </h2>

      <div class="mb-2">
        <strong>Zielgruppe:</strong> Zahnärzte und Praxismanager
      </div>
      <div class="mb-2">
        <strong>Ziel:</strong> Erfahren Sie, wie Sie im Engpassmarkt ZFA das leistungsmäßig obere Drittel
        der am Markt verfügbaren Kandidatinnen für Ihre Praxis gewinnen können, ohne fünfstellige Beträge für Recruiting Agenturen zu verwenden.


      </div>
      <div class="mb-2">
        <strong>Inhalt:</strong> DEs gibt heute kaum noch eine Praxis, die nicht mehr oder weniger händerin-
gend Mitarbeiterinnen sucht. Das klassische Recruiting über Social Media Ads,
wie es immer mehr Agenturen als lukratives Geschäftsfeld für sich entdeckt
haben und Ihnen als „Rettung“ anpreisen, wirkt bei zunehmender Wettbe-
werbsintensität immer weniger gut. In dieser Power Session lernen Sie die 7
entscheidenden Erfolgsfaktoren für eine Mitarbeiterinnengewinnung kennen,
die Sie SELBST initiieren und steuern können, um gezielt das leistungsmäßig
obere Drittel der am Markt verfügbaren Mitarbeiterinnen für Ihre Praxis zu ge-
winnen. Sie bekommen bewährte Strategien und hundertfach erprobte Tipps,
die Sie direkt am Tag nach der Power Session umsetzen können.


      </div>
      <div class="mb-2"><b>Auf den Punkt: </b>
         <em>„Wer das leistungsmäßig obere Drittel der am Markt verfügbaren ZFA sucht,
wird nicht umhinkommen, dies mit einer Recruiting Strategie zu tun, deren
Qualität sich an den Erwartungen dieses oberen Drittels ausrichtet.“
</em> <br>  Zitat: Frank Frenzel
      </div>
     
      <div class="mb-2">
        <strong>Dauer:</strong> 60 Minuten
      </div>
      <div class="mb-2">
        <strong>Teilnehmerzahl:</strong> max. 60 Teilnehmer
      </div>
      <div class="mb-2">
        <strong>Durchführung:</strong> Zoom-Webinar: Den Anmeldelink und Ihre
        Zugangsdaten erhalten Sie ca. 7 Tage vor dem Webinar per E-Mail.
      </div>
  </v-container>
</template>

<script setup>

</script>
