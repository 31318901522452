<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <component :is="currentComponent" :key="WebinarId" />

        <h2 class="text-blue my-4">Das sind die nächsten Termine</h2>

        <LineTermin
          v-for="termin in termine"
          :key="termin.wter_id"
          :termin="termin"
        />
      </v-col>
      <v-col cols="12" md="3">
        <div class="pa-2">
          <div class="my-2">
            <img
              style="width: 230px"
              :src="require('@/assets/site/zacoach.jpg')"
            />
          </div>
          <div>
            <b>Dipl.-Psych. Frank Frenzel</b>
            <ul>
              <li>Führender Zahnarzt-Coach in Deutschland</li>
              <li>
                Betreiber des Nr. 1 Youtube-Kanals für Praxis-Management und
                -Kommunikation: @MeinZahnarztCoach
              </li>
            </ul>
          </div>
          <div class="mt-4">
            <strong>Über den Dozenten:</strong><br />
            Frank Frenzel, Dipl.-Psych. und Praxiscoach, betreut und berät seit
            über 2 Jahrzehnten Zahnarztpraxen bei der Entwicklung und Einführung
            von unternehmerischen Konzepten und Marketingstrategien. 2021
            gründete er seinen Youtube-Kanal MeinZahnarztCoach der mittlerweile
            über 3.000 Abonnenten zählt.
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import BaseCalls from "@/services/BaseCalls";
import LineTermin from "@/components/veranstaltung/LineTermin";
import ByeBema from "@/components/veranstaltung/themes/ByeBema.vue";
import ProfitBoosterPzr from "@/components/veranstaltung/themes/ProfitBoosterPzr.vue";
import RecruitingZahnarztZfa from "@/components/veranstaltung/themes/RecruitingZahnarztZfa.vue";

const route = useRoute();
const WebinarId = ref(0);
const currentComponent = ref(null);
const termine = ref([]);

const updateComponent = async () => {
  if (route.params.info === 'webinar_by_by_bema') {
    WebinarId.value = 1;
    currentComponent.value = ByeBema;
  } else if (route.params.info === 'webinar_profit_booster_professionelle_zahnreinigung') {
    WebinarId.value = 2;
    currentComponent.value = ProfitBoosterPzr;
  } else if (route.params.info === 'webinar_recruiting_zahnarzt_zfa') {
    WebinarId.value = 3;
    currentComponent.value = RecruitingZahnarztZfa;
  }

  if (WebinarId.value > 0) {
    await getTermine();
  }
};

const getTermine = async () => {
  try {
    const data = {
      wter_webinar_id: WebinarId.value,
      action: "getWebinarTerminePerId",
    };
    const resp = await BaseCalls.postWebinar(data);
    if (resp.data && resp.data.termine) {
      termine.value = resp.data.termine;
    }
  } catch (err) {
    console.log("Fehler bei der Aktion:", err.message);
  }
};

onMounted(() => {
  updateComponent();
});

watch(
  () => route.params.info,
  () => {
    updateComponent();
  }
);
</script>
